<template>
  <v-menu
    v-model="timeMenu"
    :close-on-content-click="false"
    :offset-y="allowTimeInput"
  >
    <template v-slot:activator="{ on, attr }">
      <v-text-field
        v-bind="attr"
        v-on="on"
        :readonly="!allowTimeInput"
        :placeholder="$t('pleaseSelectATime')"
        :prepend-icon="noIcon ? '' : (icon ? icon : 'mdi-clock')"
        :label="label"
        :filled="filled"
        :dense="dense"
        v-model="timeValue"
        :hide-details="hideDetails"
        :disabled="disabled"
        v-mask="'##:##'"
        :outlined="outlined"
        :clearable="clearable"
        @click:clear="clearTime"
      />
    </template>
    <TimeSelector
      @selectTime="setTime"
      @selectTimeWithButton="setTimeWithButton"
      @close="timeMenu = false"
      :time="timeValue"
      v-model="timeMenu"
    />
  </v-menu>
</template>

<script>
import TimeSelector from "@/components/common/TimeSelector"

export default {
	name: "TimeSelection",
	components: {
		TimeSelector
	},
	props: {
		value: {
      type: String,
      default: ''
    },
		filled: {
      type: Boolean
    },
		label: {
      type: String,
      default: ''
    },
		dense: {
      type: Boolean
    },
		icon: {
      type: String,
      default: ''
    },
		required: {
      type: Boolean
    },
		hideDetails: {
      type: Boolean,
    },
		noIcon: {
      type: Boolean,
    },
		disabled: {
      type: Boolean,
    },
		allowTimeInput: {
      type: Boolean,
    },
    outlined: {
      type: Boolean
    },
    clearable: {
      type: Boolean,
    },
    updateOnButtonClick: {
    type: Boolean
    }
	},
	data() {
		return {
			timeMenu: false,
			time: null,
		}
	},
	mounted() {
    if(this.value !== null && this.value !== undefined) {
      this.$emit('changeTimeValue', this.value.padStart(4, '0'))
      this.$emit('input', this.value.padStart(4, '0'))
    }
	},
	computed: {
		systemTimeInMillis() {
			return this.$store.getters.systemTime
		},
		timeValue: {
			get() {
				if(!this.value || this.value === '') {
					return null
				}

				if(this.value.includes(':')) {
					return this.value
				}

				let t = this.value

				if(t.length <= 2) {
					return t
				}

				return t.substr(0,2) + ':' + t.substr(2,2)
			},
			set(value) {
        this.$emit('changeTimeValue', (value ? value.split(':').join('') : value))
        this.$emit('input',  (value ? value.split(':').join('') : value))
			}
		}
	},
	methods: {
		setTime(value) {
      if(this.updateOnButtonClick === false) {
        this.$emit('changeTimeValue', (value ? value.split(':').join('') : value))
        this.$emit('input',  (value ? value.split(':').join('') : value))
      }
		},
    setTimeWithButton(value) {
      this.$emit('changeTimeValue', (value ? value.split(':').join('') : value))
      this.$emit('input',  (value ? value.split(':').join('') : value))
    },
    clearTime() {
      this.$emit('changeTimeValue', null)
      this.$emit('input', null)
    }
	},
}
</script>

<style scoped>

</style>
