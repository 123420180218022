<template>
  <v-select
    v-bind="$attrs"
    :items="weekdays"
    :value="value"
    @input="updateInput"
  />
</template>

<script>
export default {
  name: "WeekdaySelection",
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    weekdays() {
      return this.$store.getters.weekdays
    }
  },
  methods: {
    updateInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>