<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    @input="updateInput"
    class="simple-price-field"
  >
    <template v-slot:append>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            label
            small
            v-on="on"
            v-bind="attrs"
          >
            {{ currencyCode }}
          </v-chip>
        </template>
        <v-card class="pa-2">
          <CurrencySelection
            :label="$t('currency')"
            hide-details
            filled
            :selected-currency="currencyCode"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import CurrencySelection from "@/components/common/pricing/CurrencySelection"
export default {
  name: "SimplePrice",
  components: {
    CurrencySelection
  },
  props: {
    currencyCode: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: null
    }
  },
  methods: {
    updateInput(value) {
      this.$emit('input', Number(value))
    }
  }
}
</script>

<style scoped>
.simple-price-field >>> .v-input__append-inner {
  margin-top: 0 !important;
  margin-bottom: 8px;
  align-self: end !important;
}
</style>