<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="3"
        class="pb-0"
      >
        <WeekdaySelection
          filled
          hide-details
          :label="$t('Weekday')"
          :placeholder="$t('pleaseSelectAWeekday')"
          persistent-placeholder
          v-model="weekdayPrice.weekday"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <TimeSelection
          :label="$t('from')"
          filled
          no-icon
          hide-details
          v-model="hourFrom"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <TimeSelection
          :label="$t('to')"
          filled
          no-icon
          hide-details
          v-model="hourTo"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <SimplePrice
          filled
          hide-details
          :label="$t('Price')"
          :currency-code="currencyCode"
          v-model="priceSimple"
          @updateCurrency="updateCurrency"
        />
      </v-col>
      <v-col
        cols="12"
        class="text-end pt-0 pb-0"
      >
        <v-btn
          text
          color="error"
          @click="removeWeekdayPrice"
        >
          {{ $t('remove') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WeekdaySelection from "@/components/common/datetime/WeekdaySelection"
import TimeSelection from "@/components/common/TimeSelection"
import SimplePrice from "@/components/common/pricing/SimplePrice"

export default {
  name: "WeekdayPrice",
  components: {
    SimplePrice,
    TimeSelection,
    WeekdaySelection
  },
  props: {
    weekdayPrice: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    priceSimple: {
      get() {
        if(this.price && typeof this.price.amount !== 'undefined') {
          return this.price.amount
        }
        return null
      },
      set(value) {
        this.updateAmount(value)
      }
    },
    price() {
      if(typeof this.weekdayPrice !== 'undefined' && this.weekdayPrice !== null) {
        if(typeof this.weekdayPrice.price !== 'undefined' && this.weekdayPrice.price !== null) {
          return this.weekdayPrice.price
        }
      }
      return null
    },
    currencyCode() {
      if(this.price) {
        return this.price.currency_code
      }
      return ''
    },
    hourFrom: {
      get() {
        if(typeof this.weekdayPrice !== 'undefined' && this.weekdayPrice !== null) {
          if(typeof this.weekdayPrice.hourStart !== 'undefined' && this.weekdayPrice.hourStart !== null) {
            return this.weekdayPrice.hourStart
          }
        }
        return '00:00' // default value
      },
      set(value) {
        const newWeekdayPrice = this.weekdayPrice
        newWeekdayPrice.hourStart = value
        this.updateWeekdayPrice(newWeekdayPrice)
      }
    },
    hourTo: {
      get() {
        if(typeof this.weekdayPrice !== 'undefined' && this.weekdayPrice !== null) {
          if(typeof this.weekdayPrice.hourEnd !== 'undefined' && this.weekdayPrice.hourEnd !== null) {
            return this.weekdayPrice.hourEnd
          }
        }
        return '23:59' // default value
      },
      set(value) {
        const newWeekdayPrice = this.weekdayPrice
        newWeekdayPrice.hourEnd = value
        this.updateWeekdayPrice(newWeekdayPrice)
      }
    }
  },
  methods: {
    removeWeekdayPrice() {
      this.$emit('removeWeekdayPrice', this.index)
    },
    updateWeekdayPrice(weekdayPrice) {
      this.$emit('updateWeekdayPrice', { value: weekdayPrice, index: this.index })
    },
    updateAmount(amount) {
      const newWeekdayPrice = this.weekdayPrice
      newWeekdayPrice.price.amount = amount
      this.updateWeekdayPrice(newWeekdayPrice)
    },
    updateCurrency(currencyCode) {
      const newWeekdayPrice = this.weekdayPrice
      newWeekdayPrice.price.currency_code = currencyCode
      this.updateWeekdayPrice(newWeekdayPrice)
    }
  }
}
</script>

<style scoped>

</style>