<template>
  <v-autocomplete
    :items="currencies"
    item-text="name"
    item-value="code"
    :disabled="disabled"
    :filled="filled"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :hint="hint"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :persistent-hint="persistentHint"
    :placeholder="placeholder"
    :value="selectedCurrency"
    :filter="currencyFilter"
    @change="selectCurrency"
  >
    <template v-slot:selection="{ item }">
      <span>
        <span>
          {{ item.name }}
        </span>
        <span class="text--secondary">
          ({{ item.code }})
        </span>
      </span>
    </template>
    <template v-slot:item="{ item }">
      <span>
        <span>
          {{ item.name }}
        </span>
        <span class="text--secondary">
          ({{ item.code }})
        </span>
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "CurrencySelection",
  props: {
    selectedCurrency: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectCurrency(currencyCode) {
      this.$emit('updateCurrency', currencyCode)
    },
    currencyFilter(item, queryText, itemText) {
      const query = queryText ? queryText.toLowerCase() : queryText
      const itemName = item ? item.name.toLowerCase() : null
      const itemCode = item ? item.code.toLowerCase() : null
      if(typeof query !== 'undefined' && query !== null) {
        if(typeof itemName !== 'undefined' && itemName !== null && typeof itemCode !== 'undefined' && itemCode !== null) {
          return itemName.includes(query) || itemCode.includes(query)
        }
        if(typeof itemName !== 'undefined' && itemName !== null) {
          return itemName.includes(query)
        }
        if(typeof itemCode !== 'undefined' && itemCode !== null) {
          return itemCode.includes(query)
        }
      }
      return false
    }
  },
  computed: {
    currencies() {
      return this.$store.state.currencies
    }
  }
}
</script>

<style scoped>

</style>