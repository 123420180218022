<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row dense>
      <template v-for="(weekdayPrice, index) in weekdayPrices">
        <v-col
          :key="'wp-' + index"
          cols="12"
        >
          <WeekdayPrice
            :weekday-price="weekdayPrice"
            :index="index"
            v-bind="$attrs"
            v-on="$listeners"
          />
          <v-divider />
        </v-col>
      </template>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          text
          @click="addNewWeekdayPrice"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('addNewWeekdayPrice') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WeekdayPrice from "@/components/common/pricing/WeekdayPrice"

export default {
	name: 'WeekdayPrices',
	components: {
    WeekdayPrice
	},
	props: {
    weekdayPrices: {
      type: Array,
      default: () => []
    }
	},
	methods: {
    addNewWeekdayPrice() {
      this.$emit('addNewWeekdayPrice')
    }
	}
}
</script>

<style scoped>

</style>